import { ElMessage } from 'element-plus'

// 自定义 Message 函数，添加默认的 offset 设置
const myMessage = ({ message, type = 'success', duration = 2000, offset = 300, onClose = () => { } }) => {
  ElMessage({
    message,
    type,
    duration,
    offset, // 设置默认的 offset
    onClose
  })
}

export default myMessage
